import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'components/Markdown'

const ImageAndWordsCollection = props => {
  return (
    <div className="section section--padded-big">
      <div className="section__inner">
        <h2 className="headline tc">{props.title}</h2>
        <div className="features">
          {props.elements.map(element => (
            <div key={element.id} className="features__item">
              <img
                src={element.image.url}
                alt={element.image.description}
                className="features__image"
              />
              <h4 className="article__card--title">{element.title}</h4>
              <Markdown>{element.text}</Markdown>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ImageAndWordsCollection

ImageAndWordsCollection.propTypes = {
  title: PropTypes.string,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        description: PropTypes.string,
      }),
    })
  ),
}
